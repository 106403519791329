import React, { useEffect, useRef, useState } from "react"
import { Content, Row, Col } from "../../styles/StyledElements"

import { FoundedMapChart } from "./foundedMapChart"
import { InputScrubber } from "./inputScrubber"

export function LayoutFounded({ dataset }) {
  const chartStartYear = 1995
  const [year, setYear] = useState(chartStartYear)

  const scrubberUpdate = React.useCallback(selected => {
    setYear(selected)
  }, [])

  return (
    <Content>
      <Row>
        <Col>
          <InputScrubber
            values={dataset.years.filter(d => d >= chartStartYear)}
            scrubberUpdate={scrubberUpdate}
          />
        </Col>
      </Row>
      <Row>
        <Col size={2}>
          <FoundedMapChart
            dataset={dataset}
            scrubberYear={year}
            chartStartYear={chartStartYear}
          />
        </Col>
      </Row>
    </Content>
  )
}
