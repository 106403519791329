import React, { useEffect, useState, useRef } from "react"
import * as d3 from "d3"
import styled from "styled-components"
import { voronoiTreemap } from "d3-voronoi-treemap"

import colors from "../../content/data/category-colors.json"

const seedrandom = require("seedrandom")

const vizTitle = `Share of investment and employees by category. `
const vizDesc = `Vizualization showing share of total company insvestment or employees by category` // Accessibility
const vizSource = `Source: Urban Tech Hub Analysis`

const ChartWrapper = styled.div`
  /* margin: 0 auto; */
  position: relative;

  .label-cat {
    font-weight: bold;
    font-family: ${({ theme }) => theme.type.sans};
    font-size: 1rem;
  }

  svg {
    transition: transform 2s;

    /* overflow: auto; */
    width: 100%; // Was breaking IE display
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: auto;
    }
  }

  &.cover-mode svg {
    transform: scale(2);
  }
`

const TooltipBox = styled.div`
  position: absolute;
  z-index: 1000;
  text-align: left;
  min-width: 30px;
  border-radius: 4px;
  height: auto;
  background: rgba(250, 250, 250, 0.9);
  border: 1px solid #ddd;
  font-size: 0.8rem;
  padding: 4px 8px;
  opacity: 0;
  font-family: ${props => props.theme.type.sans};
  span {
    text-transform: capitalize;
  }
`

export function VoronoiTree({
  catMap,
  hierarchy,
  labeling,
  selectedView,
  categoryFocus = "all",
  categorySelection = null,
  //selectedSubcategory = null,
  handleClick,
  vizId = "voronoi-tree",
  height = 300,
}) {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  let width = 700

  const divRef = useRef(null)
  // const selectionRef = useRef(null)

  function drawChart() {
    // console.log("DRAW VORONOI CHART")
    // console.log(dataset)

    // let selectionSVG = d3.select(divRef.current).select("svg") // Unused

    width = divRef.current.getBoundingClientRect().width

    // console.log(width)
    let margin = { top: 20, right: 20, bottom: 20, left: 20 }

    let innerHeight = height - margin.top - margin.bottom
    let innerWidth = width - margin.left - margin.right

    // filtering previous teams that havent scored more than 2 points in a season.
    // let selectSeries = standings.series.filter(d => d.maxpoints > 2)

    // Define SVG
    const svg = d3
      .create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("id", vizId)
      .attr("aria-label", vizDesc)

    // Main chart group/area inset by top/left margin
    // const group = svg
    //   .append("g")
    //   .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    function isNum(n) {
      return !isNaN(parseFloat(n)) && isFinite(n) && n !== ""
    }

    //let g = svg.append("g")

    // g.append("text")
    //   .attr("class", "chart-source")
    //   .text("Voronoi Tree Chart")
    //   .attr("text-anchor", "left")
    //   .attr("x", margin.left)
    //   .attr("y", height - margin.bottom)
    //   .attr("paint-order", "stroke")
    //   .attr("stroke-linejoin", "round")
    //   .attr("stroke", "#FFF")
    //   .attr("stroke-width", "6px")
    //   .attr("fill", function (d) {
    //     return "#999"
    //   })
    //   .style("font-size", "12px")

    // use the smallest dimension to ensure fit
    let ellipseSize = d3.min([innerWidth, innerHeight])

    let ellipseWidth = ellipseSize
    let ellipseHeight = ellipseSize

    let ellipse = d3
      .range(100)
      .map(i => [
        (ellipseWidth * (1 + 0.99 * Math.cos((i / 50) * Math.PI))) / 2,
        (ellipseHeight * (1 + 0.99 * Math.sin((i / 50) * Math.PI))) / 2,
      ])

    // var mySeededPrng = new Math.seedrandom('my seed'); // (from seedrandom's doc) Use "new" to create a local prng without altering Math.random
    // voronoiTreemap.prng(mySeededPrng);

    //let seed = new Math.seedrandom(5)
    let seed = seedrandom(5)
    let voronoiTreeMap = voronoiTreemap().prng(seed).clip(ellipse)

    voronoiTreeMap(hierarchy)

    // const root = d3.pack()
    //   .size([innerWidth, innerHeight])
    //   .padding(4)

    // (hierarchy)

    let format = d3.format(",d")

    // const svg = d3
    //   .create("svg")
    //   .attr("viewBox", [0, 0, width, height])
    //   .attr("id", chartid)

    let offset = (innerWidth - ellipseWidth) / 2

    const group = svg
      .append("g")
      .attr(
        "transform",
        "translate(" + (margin.left + offset) + "," + margin.top + ")"
      )

    let allNodes = hierarchy
      .descendants()
      .sort((a, b) => b.depth - a.depth)
      .map((d, i) => Object.assign({}, d, { id: i }))

    group
      .selectAll("path")
      .data(allNodes)
      .enter()
      .append("path")
      .attr("d", d => "M" + d.polygon.join("L") + "Z")
      // .style("fill", d => (d.parent ? d.parent.color : d.color))
      .attr("stroke", "#F5F5F2")
      .attr("stroke-width", 0)

      .attr("class", d => {
        return `voronoi-path voronoi-level-${d.depth}`
      })
      .style("fill-opacity", d => (d.depth === 2 ? 1 : 0))
      .attr("pointer-events", d => (d.depth === 2 ? "all" : "none"))
      .attr("stroke-width", d => 7 - d.depth * 2.8)
    // .attr("fill", d => {
    //   if (d.depth === 2) {
    //     if (categoryFocus === "all" || categoryFocus === d.parent.data[0]) {
    //       return colors[d.parent.data[0]]
    //     } else {
    //       return "#999"
    //     }
    //   }
    // })

    const hover = group.append("g").attr("class", "hover")

    hover
      .selectAll("path")
      .data(allNodes.filter(d => d.depth === 2))
      .enter()
      .append("path")
      .attr("class", "subcat-hover")
      .attr("d", d => "M" + d.polygon.join("L") + "Z")
      .attr("fill", "#fff")
      .attr("fill-opacity", "0")
      .attr("stroke", "none")
      .attr("stroke-width", 3)
      .on("mouseover", nodeMouseOver)
      .on("mouseout", nodeMouseOut)
      .on("click", nodeClick)

    const labels = group
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    labels
      .selectAll("text")
      .data(allNodes.filter(d => d.depth === 1))
      .enter()
      .append("text")
      .attr("class", d => `label-cat label-${d.id}`)
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("x", -10)
      .attr("y", d => {
        // shift information-systems
        return d.data[0] === "information-systems" ? -10 : 0
      })
      .attr(
        "transform",
        d => `translate(${d.polygon.site.x},${d.polygon.site.y - 16})`
      )
      .attr("opacity", d => (labeling ? 1 : 0))
      .text(d => catMap.get(d.data[0])[0].nameShort)
      .attr("cursor", "default")
      .attr("pointer-events", "none")

    applyStyle(svg)

    return svg
  }

  function applyStyle(selectionSVG) {
    // console.log("APPLY STYLE")
    // console.log("Color Forecast: " + colorForecast)

    // if (isFirstLoad) {
    //   setIsFirstLoad(false)
    //   // Standard Style Update
    //   console.log("FIRST LOAD")
    //   selectionSVG
    //     .selectAll(".voronoi-path")
    //     .transition()
    //     .duration(500)
    //     .delay(function (d, i) {
    //       return i * 25 + 250
    //     })
    //   // .attr("fill", d => {
    //   //   if (d.depth === 2) {
    //   //     if (categoryFocus === "all" || categoryFocus === d.parent.data[0]) {
    //   //       return colors[d.parent.data[0]]
    //   //     } else {
    //   //       return "#999"
    //   //     }
    //   //   }
    //   // })
    // } else {
    //   console.log("NEXT LOAD")
    //   selectionSVG
    //     .selectAll(".voronoi-path")
    //     .transition()
    //     .duration(500)
    //     .attr("fill", d => {
    //       if (d.depth === 2) {
    //         if (categoryFocus === "all" || categoryFocus === d.parent.data[0]) {
    //           return colors[d.parent.data[0]]
    //         } else {
    //           return "#999"
    //         }
    //       }
    //     })
    // }

    selectionSVG
      .selectAll(".voronoi-path")
      .transition()
      .duration(500)
      .attr("fill", d => {
        if (d.depth === 2) {
          if (categoryFocus === "all" || categoryFocus === d.parent.data[0]) {
            return colors[d.parent.data[0]]
          } else {
            return "#999"
          }
        }
      })

    selectionSVG
      .selectAll(".subcat-hover")
      .transition()
      .duration(500)
      .attr("stroke", d => {
        if (categorySelection === d.data[0]) {
          return "red"
        } else {
          return "none"
        }
      })

    selectionSVG
      .selectAll(".label-cat")
      .transition()
      .duration(500)
      .attr("opacity", d => (labeling ? 1 : 0))
      .attr("fill", d => {
        if (categoryFocus === "all" || categoryFocus === d.data[0]) {
          return "#111"
        } else {
          return "#777"
        }
      })
  }

  const addTooltip = (d, x, y) => {
    //div.transition().duration(200).style("opacity", 0.9)
    d3.select(`#${vizId}-tooltip`).style("opacity", 0.9)
    d3.select(`#${vizId}-tooltip`)
      //.html(hoverTooltip(d))
      .html(`<div><strong>${catMap.get(d.data[0])[0].nameShort}</strong></div>`)
      .style("pointer-events", "none")
      .style("max-width", "250px")
      .style("z-index", 300)
      .style("left", `${x + 24}px`)
      .style("top", `${y}px`)
  }

  const removeTooltip = () => {
    //div.transition().duration(200).style("opacity", 0)
    d3.select(`#${vizId}-tooltip`).style("opacity", 0)
  }

  function nodeMouseOver(event, d) {
    //console.log(d)
    d3.select(event.target).attr("stroke", "red")
    //addTooltip(d, d.x + margin.left, d.y + margin.top)
    addTooltip(d, d.polygon.site.x, d.polygon.site.y)
  }
  function nodeMouseOut(event, d) {
    d3.select(event.target).attr("stroke", d => {
      //console.log(d.data[0])
      //console.log(categorySelection)
      if (d.data[0] === categorySelection) {
        return "red"
      } else {
        return "none"
      }
    })
    removeTooltip()
  }
  function nodeClick(event, d) {
    // d3.select(event.target).attr("stroke", "none")
    // console.log(d.data[0])
    //console.log(categorySelection)
    handleClick(d.data[0])
    //console.log(categorySelection)
    // if (d.data[0] === categorySelection) {
    //   handleClick(null)
    // } else {
    //   handleClick(d.data[0])
    // }
  }

  useEffect(() => {}, [])

  //ComponentDidMount - Initialize
  useEffect(() => {
    // console.log(`ComponentDidMount`)

    //Append d3 svg to ref div
    if (hierarchy) {
      var div = d3.select(divRef.current)
      let svg = drawChart()
      if (div.node().firstChild) {
        div.node().removeChild(div.node().firstChild)
      }
      div.node().appendChild(svg.node())
    }
  }, [hierarchy])

  useEffect(() => {
    // console.log(`ComponentUpdate3`)
    var div = d3.select(divRef.current)

    applyStyle(div.select("svg"))
  }, [labeling, categoryFocus, categorySelection])

  // useEffect(() => {
  //   // console.log(`ComponentUpdate3`)
  //   var div = d3.select(divRef.current)
  //   applyStyle(div.select("svg"))
  // })

  //Render
  return (
    <ChartWrapper className={selectedView === "cover" ? "cover-mode" : null}>
      {/* <h4>{vizTitle}</h4> */}
      <div className={"maindiv"} id={vizId} ref={divRef} />
      <TooltipBox id={`${vizId}-tooltip`}></TooltipBox>
    </ChartWrapper>
  )
}
