import * as React from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionMeta,
  Content,
  Row,
  Col,
} from "../styles/StyledElements"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import styled from "styled-components"
// import { below } from "../styles/utilities/breakpoints"

import { processCompanies } from "../components/viz/processCompanies"
import { LayoutForceMap } from "../components/viz/layoutForceMap"
import { contentMapMarkdown } from "../components/pageUtilities"
import { LayoutCategoryScroll } from "../components/viz/layoutCategoryScroll"
import { LayoutFounded } from "../components/viz/layoutFounded"
import { LayoutBeeswarm } from "../components/viz/layoutBeeswarm"

export default function IndexPage({ data }) {
  // console.log(data)
  let dataset = processCompanies(data)
  console.log(dataset)

  const markdownMap = contentMapMarkdown(data.markdown.nodes)
  const title = data.site.siteMetadata?.title

  return (
    <Layout>
      <Seo />
      {/* <Section>
        <Content>
          <IntroWrapper>
            <div className="title">
              <h1>{data.site.siteMetadata?.title}</h1>
              <div className="subtitle">
                {data.site.siteMetadata?.description}
              </div>
            </div>
          </IntroWrapper>
        </Content>
      </Section> */}
      {/* <Section>
        <SummaryNumbers dataset={dataset} />
      </Section> */}
      <Section>
        <Content>
          {/* <ScrollLayoutTree data={data} markdownMap={markdownMap} /> */}
          <LayoutCategoryScroll
            data={dataset}
            siteMetadata={data.site.siteMetadata}
            markdownMap={markdownMap}
          />
        </Content>
      </Section>
      <Section>
        <Content>
          <Row collapse={"lg"}>
            <Col size={1}>
              <h3>{markdownMap.get("intro-beeswarm")?.frontmatter.title}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: markdownMap.get("intro-beeswarm")?.html,
                }}
              />
            </Col>
          </Row>
          <LayoutBeeswarm dataset={dataset} />
        </Content>
      </Section>
      <div style={{ height: "120px" }}></div>
      <Section>
        <Content>
          <Row collapse={"lg"}>
            <Col size={1}>
              <blockquote className={"statement"}>
                <p>{markdownMap.get("intro-map")?.frontmatter.title}</p>
              </blockquote>
            </Col>
            <Col size={2} className="details">
              <div
                dangerouslySetInnerHTML={{
                  __html: markdownMap.get("intro-map")?.html,
                }}
              />
            </Col>
          </Row>
        </Content>
        <LayoutForceMap dataset={dataset} />
      </Section>
      <div style={{ height: "120px" }}></div>
      <Section>
        <Content>
          <Row collapse={"lg"}>
            <Col size={1}>
              <blockquote className={"statement"}>
                <p>{markdownMap.get("intro-founding")?.frontmatter.title}</p>
              </blockquote>
            </Col>
            <Col size={2} className="details">
              <div
                dangerouslySetInnerHTML={{
                  __html: markdownMap.get("intro-founding")?.html,
                }}
              />
            </Col>
          </Row>
        </Content>
        <LayoutFounded dataset={dataset} />
      </Section>
      <div style={{ height: "120px" }}></div>
      <SectionMeta>
        <Content>
          <Row>
            <Col>
              <div
                dangerouslySetInnerHTML={{
                  __html: markdownMap.get("form-callout")?.html,
                }}
              />
            </Col>
          </Row>
        </Content>
      </SectionMeta>
    </Layout>
  )
}

//export default IndexPage
export const query = graphql`
  {
    items: allAirtable(filter: { table: { eq: "Organization List" } }) {
      nodes {
        recordId
        data {
          Company
          Location
          Year_Founded
          Year_Acquired_or_Dissolved
          Dissolution_Type
          Number_of_Employees
          Employees
          Latitude
          Longitude
          Total_Investment
          Categories {
            recordId
            data {
              Name
              Name_Short
            }
          }
          Subcategories {
            recordId
            data {
              Name
              Name_Short
            }
          }
        }
      }
    }
    categories: allAirtable(filter: { table: { eq: "Categories" } }) {
      nodes {
        recordId
        data {
          Name
          Name_Short
          Order
          Description {
            childMarkdownRemark {
              html
            }
          }
          Subcategories {
            recordId
            data {
              Name
              Name_Short
              Description {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    markdown: allMarkdownRemark(
      filter: { frontmatter: { section: { in: ["intro", "descriptions"] } } }
    ) {
      nodes {
        html
        frontmatter {
          ref
          section
          title
        }
      }
    }
  }
`
