import React from "react"
import styled from "styled-components"
import * as d3 from "d3"

import iconCompanies from "../../assets/icon-companies.svg"
import iconEmployees from "../../assets/icon-employees.svg"
import iconInvestment from "../../assets/icon-investment.svg"

const SummaryStatWrapper = styled.div`
  display: flex;
  flex-basis: 0;

  .stat {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem;
    font-family: ${props => props.theme.type.sans};
    flex: 1;
    background-color: rgba(255, 255, 255, 0.85);

    .content {
      padding-top: 0.5rem;
    }

    .number {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1;
    }
    .label {
      font-weight: 800;
      font-size: 1rem;
      line-height: 1;
      margin-bottom: 0.25rem;
    }
    .note {
      font-size: 0.8rem;
      color: #777;
    }

    .icon {
      margin-top: 0.25rem;
      width: 1.6rem;
      height: 1.6rem;
      background-color: #333;
      border-radius: 50%;
    }

    &.stat-companies .icon {
      background-image: url(${iconCompanies});
    }
    &.stat-employees .icon {
      background-image: url(${iconEmployees});
    }
    &.stat-investment .icon {
      background-image: url(${iconInvestment});
    }
  }
`

const SummaryStatWrapperSmall = styled.div`
  display: flex;
  flex-basis: 0;
  background-color: #eeeeee;
  border-radius: 2rem;

  .stat {
    display: flex;
    align-items: center;
    font-family: ${props => props.theme.type.sans};
    padding-left: 0.2rem;
    height: 2rem;
    .number {
      padding-left: 0.5rem;
      padding-right: 1rem;
      font-weight: 700;
    }
    .label {
      line-height: 1;
      font-size: 0.85rem;
    }
    .note {
      color: #777;
    }

    .icon {
      width: 1.6rem;
      height: 1.6rem;
      background-color: #777;
      border-radius: 50%;
    }

    &.stat-companies .icon {
      background-image: url(${iconCompanies});
    }
    &.stat-employees .icon {
      background-image: url(${iconEmployees});
    }
    &.stat-investment .icon {
      background-image: url(${iconInvestment});
    }
  }
`
export function SummaryNumbers({ dataset }) {
  let summary = dataset.summary

  return (
    <SummaryStatWrapper>
      <div className={"stat stat-companies"}>
        <div className={"icon"}></div>
        <div className={"content"}>
          <div className={"label"}>Enterprises</div>
          <div className={"number"}>{summary.companies.total}</div>
          <div className={"note"}></div>
        </div>
      </div>

      <div className={"stat stat-investment"}>
        <div className={"icon"}></div>
        <div className={"content"}>
          <div className={"label"}>Investment</div>
          <div className={"number"}>
            {d3.format("$.3s")(summary.investment.total).replace("G", "B")}
          </div>

          <div className={"note"}>
            {d3.format(".0%")(
              (summary.companies.total - summary.investment.missing) /
                summary.companies.total
            )}{" "}
            reporting
          </div>
        </div>
      </div>
      <div className={"stat stat-employees"}>
        <div className={"icon"}></div>
        <div className={"content"}>
          <div className={"label"}>Employees</div>
          <div className={"number"}>
            {d3.format(".3s")(summary.employees.total)}
          </div>

          <div className={"note"}>
            {d3.format(".0%")(
              (summary.companies.total - summary.employees.missing) /
                summary.companies.total
            )}{" "}
            reporting
          </div>
        </div>
      </div>
    </SummaryStatWrapper>
  )
}

export function SummaryNumbersCategory({ data }) {
  return (
    <SummaryStatWrapperSmall>
      <div className={"stat stat-companies"}>
        <div className={"icon"}></div>
        <div className={"number"} aria-label={"Total Companies"}>
          {data.companies}
        </div>
        {/* <div className={"label"}>Companies</div> */}
      </div>

      <div className={"stat stat-investment"}>
        <div className={"icon"}></div>
        <div className={"number"} aria-label={"Total Investment"}>
          {d3.format("$.3s")(data.investment).replace("G", "B")}
        </div>
        {/* <div className={"label"}>Investment</div> */}
      </div>
      <div className={"stat stat-employees"}>
        <div className={"icon"}></div>
        <div className={"number"} aria-label={"Total Employees"}>
          {d3.format(".3s")(data.employees)}
        </div>
        {/* <div className={"label"}>Employees</div> */}
      </div>
    </SummaryStatWrapperSmall>
  )
}
