import React from "react"
import styled from "styled-components"
import { rgba } from "polished"

const TagGroup = styled.div`
  font-family: ${({ theme }) => theme.type.sans};
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 2;
`

const TagElement = styled.div`
  display: inline-block;
  padding: 0px 6px;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
  background: #ddd;
`
const TagCountedColor = styled(TagElement)`
  font-family: ${({ theme }) => theme.type.sans};
  font-size: 0.9rem;
  font-weight: 600;
  display: inline-block;
  padding: 0px 6px;
  margin-right: 4px;
  margin-bottom: 2px;
  border-radius: 4px;
  background: ${props => rgba(props.color, props.opacity)};

  border: 2px solid #fff;

  .count {
    font-weight: 500;
  }

  &.active {
    border: 2px solid rgb(128, 0, 128);
  }

  &:hover {
    border: 2px solid #000;
  }
`

export const CategoryTagsSelect = ({
  array,
  type,
  color,
  selected,
  categorySelect,
}) => {
  let maxCount = Math.max.apply(
    Math,
    array.map(function (d) {
      return d.count
    })
  )
  const handleTagClick = (e, tag) => {
    e.preventDefault()
    // console.log("click")
    // console.log(tag)
    categorySelect(tag.value)
  }

  return (
    <TagGroup>
      {array?.map((tag, i) => (
        <TagCountedColor
          color={color}
          className={tag.value === selected ? "active" : null}
          onClick={e => handleTagClick(e, tag, type)}
          opacity={(tag.count / maxCount) * 0.5}
          // className={`ct-${tag.count}`}
          key={`{type}-${i}`}
        >
          {tag.label} <span className={"count"}>({tag.count})</span>
        </TagCountedColor>
      ))}
    </TagGroup>
  )
}
