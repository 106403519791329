import React, { useEffect } from "react"
import * as d3 from "d3"
import styled from "styled-components"
import { Waypoint } from "react-waypoint"
import { CategoryTagsSelect } from "./categoryTagSelect"
import { VoronoiTree } from "./voronoiTree"
import catColors from "../../content/data/category-colors.json"

import cornellLogo from "../../assets/vertical-jacobs-cornell-dark.svg"
import { above, below } from "../../styles/utilities/breakpoints"
import { InputToggleSet } from "./inputToggleSet"
import { SummaryNumbers, SummaryNumbersCategory } from "./summaryNumbers"

const WaypointWrapper = styled.div`
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    ${above.md} {
      flex-direction: row;
    }
  }

  .graphic {
    flex-basis: 60%;
    height: 100vh;
    position: sticky;
    top: 10px;
    z-index: 10;
    /* background: cornflowerblue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .graphic-control {
    opacity: 0;
    transition: opacity 0.5s ease;
    &.active {
      opacity: 1;
    }
  }

  .scroller {
    flex-basis: 35%;
    z-index: 100;
    pointer-events: none;
    ${below.md} {
      margin-top: -450px;
    }
  }

  /* .trigger {
    border-top: 1px dashed #eee;
    top: 0;
    margin-top: 33vh;
    position: fixed;
    width: 100%;
    color: #ccc;
    z-index: -1000;
  } */
`

const WaypointContent = styled.div`
  padding: 1rem;
  margin-bottom: 90vh;
  pointer-events: all;

  &.step-standard {
    background-color: rgba(255, 255, 255, 0.85);
  }

  &.step-cover {
    padding: 0;
  }

  ${above.md} {
    margin-bottom: 200px;
  }

  h2 {
    margin-top: 1rem;
    background: white;
  }

  transition: all 1s ease;

  &.active {
  }

  .total-companies {
    font-weight: bold;
  }
`

const IntroWrapper = styled.div`
  ${below.md} {
    padding-left: 25%;
  }

  .title {
    margin-top: 1rem;
    z-index: 100;
    /* padding: 1rem; */
    pointer-events: none;
    padding-left: 10px;
    padding-right: 10px;

    h1 {
      font-size: 3rem;
      line-height: 1.2;
      ${below.md} {
        font-size: 2.4rem;
      }
      margin-bottom: 0.25rem;
      background-color: white;
      box-shadow: 10px 0 0 white, -10px 0 0 white;
    }

    ${below.md} {
      margin-top: 0rem;
    }

    .subtitle {
      font-family: ${({ theme }) => theme.type.serif_alt};
      display: inline-block;
      font-size: 1.5rem;
      background-color: white;
      box-shadow: 10px 0 0 white, -10px 0 0 white;
      margin-bottom: 3rem;
    }
  }

  /* .intro {
    align-self: start;
    z-index: 100;
    padding: 1rem;
  } */
`

const HeaderLogo = styled.div`
  position: absolute;
  left: 0;
  top: 10;
  .logo {
    visibility: hidden;
    width: 80px;
    z-index: 4000;
    opacity: 0;
    transition: all 0.5s linear;
    &.cover-mode {
      visibility: visible;
      opacity: 1;
    }
  }
`

const SubCategoryLabel = styled.div`
  font-size: 0.85em;
  font-style: italic;
  margin-bottom: 0.5rem;
  color: #888;
`

const SelectionBox = styled.div`
  text-align: left;
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 300px;
  padding: 10px;
  /* background-color: rgba(240, 240, 240, 0.8); */
  box-shadow: rgb(0 0 0 / 10%) 0px 5px 15px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 400;
  border-radius: 6px;
  font-family: ${props => props.theme.type.sans};
  font-size: 0.8rem;

  h4 {
    margin-top: 0;
    line-height: 1.2;
    font-size: 0.9rem;
  }

  .type {
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  .name {
    font-family: ${props => props.theme.type.sans};
    font-weight: 700;
    line-height: 1.1;
  }
  .summary {
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  .close {
    margin-right: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.coolgrey};
    cursor: pointer;
    background: none;
    transition: all 0.5s ease;
    :hover {
      background: ${({ theme }) => theme.colors.coolgrey};
      color: #fff;
    }
  }

  .node-link {
    text-decoration: none;
    transition: all 0.5s ease;
    :hover {
      text-decoration: underline;
    }
  }

  &.active {
    display: block;
  }

  .comapany-section {
    margin-bottom: 1rem;
    button {
      border: none;
      color: ${({ theme }) => theme.colors.link};
      padding: 0;
      background: none;
      font-style: italic;
      &:hover {
        color: #333;
      }
    }

    .comapany-list {
      padding-left: 1.5rem;
      height: 4rem;
      overflow-y: scroll;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`

const voronoiValues = [
  // { value: "number", label: "Number of Companies" },
  { value: "investment", label: "Total Investment" },
  { value: "employees", label: "Total Employees" },
]

export const LayoutCategoryScroll = ({ data, siteMetadata, markdownMap }) => {
  const [selectedValue, setSelectedValue] = React.useState("investment")
  const [categoryFocus, setCategoryFocus] = React.useState("all")
  const [categorySelection, setCategorySelection] = React.useState(null)
  const [selectedView, setView] = React.useState("all")
  const [labeling, setLabeling] = React.useState(false)
  const [popupContent, setPopupContent] = React.useState("CONTENT")
  const [hierarchy, setHierarchy] = React.useState(null)
  const [boxShowCompanies, setBoxShowCompanies] = React.useState(false)

  const updateSelectedCategory = React.useCallback(value => {
    console.log({ value, categorySelection })
    if (value === categorySelection) {
      setCategorySelection(null)
    } else {
      setCategorySelection(value)
    }
    console.log({ value, categorySelection })
  })

  const updateToggleOption = React.useCallback(value => {
    //console.log(`Activate: ${view}`)
    // setVizMode(view)
    setSelectedValue(value)
  })

  const handleClick = React.useCallback(value => {
    setCategorySelection(value)
  })

  const stepUpdate = React.useCallback(view => {
    //console.log(`Activate: ${view}`)
    // setVizMode(view)
    setView(view)
    if (view === "cover") {
      setCategoryFocus("all")
      setLabeling(false)
    } else {
      setCategoryFocus(view)
      setLabeling(true)
    }
  })

  let wpTopOffset = "40%"
  let wpBottomOffset = "40%"

  // useEffect(() => {
  //   var trendCountRefs = document.getElementsByClassName("trend-count")
  //   for (var i = 0; i < trendCountRefs.length; i++) {
  //     trendCountRefs[i].innerHTML = trend_count
  //   }
  // })

  useEffect(() => {
    let companyCountRefs = document.getElementsByClassName("total-companies")
    for (let i = 0; i < companyCountRefs.length; i++) {
      companyCountRefs[i].innerHTML = data.summary.companies.total
    }
    // StickyBits Polyfill
    // console.log("stickybits")
    // console.log(stickybits)
    // stickybits(".graphic", { useStickyClasses: true })
    //setHierarchy(createHierarchy(data.set, "investment"))
    setHierarchy(createHierarchy(data.set, selectedValue))
  }, [selectedValue])

  return (
    <WaypointWrapper>
      <div className="trigger"></div>
      <div className="container">
        <div className="graphic">
          <SelectionBox
            id="subcatSelected"
            className={categorySelection !== null ? "active" : ""}
          >
            <div id="nodeSelectedInner">
              {categorySelection !== null && (
                <div>
                  <h4>{data.c.catMap.get(categorySelection)[0].name}</h4>
                  <SummaryNumbersCategory
                    data={data.c.catdataMap.get(categorySelection)[0].totals}
                  />
                  {data.c.catMap.get(categorySelection)[0].Name}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.c.catMap.get(categorySelection)[0].descHtml,
                    }}
                  />
                  {/* <div className="comapany-section">
                    <button
                      onClick={() => setBoxShowCompanies(!boxShowCompanies)}
                    >
                      {boxShowCompanies ? "hide companies" : "show companies"}
                    </button>
                    {boxShowCompanies && (
                      <ul className="comapany-list">
                        {data.c.catdataMap
                          .get(categorySelection)[0]
                          .companies.sort((a, b) =>
                            d3.ascending(a.company, b.company)
                          )
                          .map((item, i) => (
                            <li key={`cat-${item.id}`}>{item.company}</li>
                          ))}
                      </ul>
                    )}
                  </div> */}
                </div>
              )}
            </div>
            <button
              className="close"
              onClick={e => {
                setCategorySelection(null)
              }}
            >
              close
            </button>
          </SelectionBox>
          <VoronoiTree
            vizId={"voronoi"}
            selectedView={selectedView}
            hierarchy={hierarchy}
            catMap={data.c.catMap}
            categoryFocus={categoryFocus}
            categorySelection={categorySelection}
            labeling={labeling}
            height={500}
            handleClick={handleClick}
          />
          <div
            className={`graphic-control ${
              selectedView === "cover" ? null : "active"
            }`}
          >
            <InputToggleSet
              options={voronoiValues}
              selected={selectedValue}
              label={"scale by"}
              updateOption={updateToggleOption}
            />
          </div>
          <HeaderLogo>
            <img
              id={"main-logo"}
              className={`logo ${selectedView === "cover" ? "cover-mode" : ""}`}
              src={cornellLogo}
              alt="Cornell Tech Logo"
            />
          </HeaderLogo>
        </div>
        <div className="scroller">
          <WaypointStep
            type={"cover"}
            step={"cover"}
            stepUpdate={stepUpdate}
            topOffset={wpTopOffset}
            bottomOffset={wpBottomOffset}
          >
            <IntroWrapper>
              <div className="title">
                <h1>{siteMetadata?.title}</h1>
                <div className="subtitle">{siteMetadata?.description}</div>
              </div>
              <SummaryNumbers dataset={data} />
            </IntroWrapper>
          </WaypointStep>

          <WaypointStep
            step={"all"}
            stepUpdate={stepUpdate}
            topOffset={wpTopOffset}
            bottomOffset={wpBottomOffset}
          >
            {" "}
            <blockquote className={"statement"}>
              <p>{markdownMap.get("intro-categories")?.frontmatter.title}</p>
            </blockquote>
            <div
              dangerouslySetInnerHTML={{
                __html: markdownMap.get("intro-categories")?.html,
              }}
            />
            {/* <FormSelect
              id={"voronoi-value"}
              label={"Chart scale value"}
              value={selectedValue}
              //onChange={e => updateLayout(e.target.value)}
              onChange={e => setSelectedValue(e.target.value)}
              options={voronoiValues}
            /> */}
          </WaypointStep>
          {data.c.categories.map((item, i) => (
            <div key={`cat-${item.id}`}>
              <WaypointStep
                step={item.slug}
                stepUpdate={stepUpdate}
                topOffset={wpTopOffset}
                bottomOffset={wpBottomOffset}
              >
                <h3>{item.name}</h3>
                <SummaryNumbersCategory
                  data={data.c.catdataMap.get(item.slug)[0].totals}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.descHtml,
                  }}
                />
                <div className={"subcategories"}>
                  <SubCategoryLabel>
                    Number of companies by subcategory
                  </SubCategoryLabel>
                  <CategoryTagsSelect
                    color={catColors[item.slug]}
                    selected={categorySelection}
                    categorySelect={updateSelectedCategory}
                    array={data.c.catdataMap.get(item.slug)[0].subcats}
                    //array={data.c.catdataMap.get([item.slug])[0].subcats}
                    //array={data.subcatSummaryMap.get(item.catid)[0].subcats}
                    type={"category"}
                  />
                </div>
              </WaypointStep>
            </div>
          ))}
          <WaypointStep
            step={"all"}
            stepUpdate={stepUpdate}
            topOffset={wpTopOffset}
            bottomOffset={wpBottomOffset}
          ></WaypointStep>
          <div style={{ height: "100px" }}></div>
        </div>
      </div>
      <div style={{ height: "100px" }}></div>
    </WaypointWrapper>
  )
}

const WaypointStep = ({
  step,
  type = "standard",
  stepUpdate,
  topOffset,
  bottomOffset,
  children,
}) => {
  const [stepActive, setStepActive] = React.useState(false)

  // this.ActiveWaypoint_
  const onEnter = () => {
    setStepActive(true)
    stepUpdate(step)
  }

  const onLeave = () => {
    setStepActive(false)
  }

  return (
    <Waypoint
      onEnter={onEnter}
      onLeave={onLeave}
      // scrollableAncestor={window}
      topOffset={topOffset}
      bottomOffset={bottomOffset}
    >
      <WaypointContent
        className={`step step-${type} ${stepActive ? "active" : ""}`}
      >
        {children}
      </WaypointContent>
    </Waypoint>
  )
}

function createHierarchy(data, field) {
  console.log("createHierarchy")
  let config = {
    dataset: data,
    //groupByFns: [d => d.category, d => d.subcategory, d => d.id],
    groupByFns: [d => d.categorySlug, d => d.subcategorySlug],
    reduceFn: v => d3.sum(v, d => d[field]),
  }

  const defaultConfig = {
    childrenAccessorFn: ([key, value]) => value.size && Array.from(value),
    sumFn: ([key, value]) => value,
    sortFn: (a, b) => b.value - a.value,
  }
  const { dataset, reduceFn, groupByFns, childrenAccessorFn, sumFn, sortFn } = {
    ...defaultConfig,
    ...config,
  }
  const rollupData = d3.rollup(dataset, reduceFn, ...groupByFns)
  const hierarchyData = d3
    .hierarchy([null, rollupData], childrenAccessorFn)
    .sum(sumFn)
    .sort(sortFn)
  return hierarchyData
}
