import React, { useRef, useEffect } from "react"
// import PropTypes from "prop-types"
import styled from "styled-components"
//import { below } from "../styles/utilities/breakpoints"

const StyledForm = styled.form`
  font-family: ${({ theme }) => theme.type.sans};
  padding: 0.25rem;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  border-radius: 2rem;
  width: 320px;

  input {
    width: 150px;
    margin-right: 0.75rem;
  }

  .btn {
    border: none;
    border-radius: 1rem;
    margin-right: 0.75em;
    width: 5em;
    background-color: ${({ theme }) => theme.colors.coolgrey};
    color: #fff;
    padding: 0.25rem 0.5rem;
    font-weight: bold;
    font-size: 0.85rem;

    &.btn-pause {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export function InputScrubber({
  values,
  initialPos = 0,
  scrubberUpdate,
  delay = 1000,
}) {
  const [rangePos, setRangePos] = React.useState(initialPos)
  const [displayValue, setDisplayValue] = React.useState(values[initialPos])
  const [playing, setPlaying] = React.useState(false)

  function updateRangePos(pos) {
    setRangePos(pos)
    setDisplayValue(values[pos])
    scrubberUpdate(values[pos])
  }

  const min = 0 // set to initial position
  const max = values.length - 1 // set to array the length
  const interval = useRef(null)

  useInterval(
    () => {
      if (rangePos < max) {
        updateRangePos(rangePos + 1)
      } else {
        interval.current = null
        setPlaying(playing => false)
      }
    },
    playing ? delay : null
  )

  const handlePlay = e => {
    setPlaying(!playing)
  }

  const handleRangeInput = e => {
    //console.log(e.target.value)
    updateRangePos(parseInt(e.target.value, 10))
    setPlaying(false)
  }

  return (
    <StyledForm>
      <button
        className={`btn btn-${playing ? "pause" : "play"}`}
        name={"b"}
        type={"button"}
        onClick={handlePlay}
      >
        {playing ? "Pause" : "Play"}
      </button>
      <input
        name={"scrubber"}
        type={"range"}
        min={min}
        step={1}
        max={max}
        value={rangePos}
        onChange={handleRangeInput}
      />
      <label htmlFor={"scrubber"}>{displayValue}</label>
    </StyledForm>
  )
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const StyledRangeSlider = styled.div``
