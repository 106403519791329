import React, { useState } from "react"
import styled from "styled-components"
import { Row, Col } from "../../styles/StyledElements"

// import { NetworkTagFilterSelect } from "../components/viz/networkTagFilterSelect"
// import { NetworkTagFilterTabs } from "../components/viz/networkTagFilterTabs"
// import { FilteredOutline } from "../components/filteredOutline"

// import { FormSelect } from "../formSelect"
import { InputToggleSet } from "./inputToggleSet"
import { ForceMapBeeswarm } from "./forceMapBeeswarm"

const NetworkInterface = styled.div`
  font-family: ${({ theme }) => theme.type.sans};
`

const SectionOutline = styled.div`
  background: #efefef;
  padding-top: 1rem;
  padding-bottom: 2rem;
`

const { slugFormat } = require("../../utilities/slugFormat")

const optionsLayout = [
  { value: "investment", label: "Investment" },
  { value: "employees", label: "Employees" },
]
const optionsS = [
  { value: "none", label: "None" },
  { value: "investment", label: "Investment" },
  { value: "employees", label: "Employees" },
]

export function LayoutBeeswarm({ dataset }) {
  // console.log("dataset")
  // console.log(dataset)

  const [selectedNodeIds, setSelectedNodeIds] = useState([])
  const [highlighting, setHighlighting] = useState(false)

  const [selectedX, setX] = React.useState("employees")
  const [selectedY, setY] = React.useState("none")
  const [selectedS, setS] = React.useState("employees")
  const [scaleType, setScaleType] = React.useState("log")
  const [selectedLayout, setLayout] = React.useState("employees")

  function updateBeeswarmLayout(value) {
    setLayout(value)
    setX(value)
    setS(value)
  }

  // const filterUpdate = React.useCallback((results, selected) => {
  //   setSelectedNodeIds(results.map(d => d.id))
  //   setHighlighting(selected.sectors.length > 0 || selected.tags.length > 0)
  // }, [])

  return (
    <>
      <Row>
        <Col>
          {" "}
          <InputToggleSet
            options={optionsLayout}
            selected={selectedX}
            label={"select value"}
            updateOption={updateBeeswarmLayout}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ForceMapBeeswarm
            vizId={"beeswarm"}
            height="600"
            selectedX={selectedX}
            layout={selectedLayout}
            selectedS={selectedS}
            xScaleType={scaleType}
            nodesData={dataset.set}
            vizDesc={
              "Chart showing distribution of enterprises by investment and number of employees"
            }
          />
        </Col>
      </Row>
    </>
  )
}
