import React, { useState, useRef, useEffect } from "react"
// import PropTypes from "prop-types"
import styled from "styled-components"
//import { below } from "../styles/utilities/breakpoints"

const StyledForm = styled.form`
  font-family: ${({ theme }) => theme.type.sans};

  button {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.lightgrey};
    border: 1px solid #fff;
    font-size: 0.85rem;
    color: #fff;

    &.active {
      background-color: ${({ theme }) => theme.colors.coolgrey};
    }

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  .label {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.85rem;
    color: #777;
  }
`

export function InputToggleSet({ options, selected, label, updateOption }) {
  const [activeOption, setActiveOption] = useState(selected)

  const handleClick = React.useCallback((e, value) => {
    e.preventDefault()
    setActiveOption(value)
    updateOption(value)
  })

  return (
    <StyledForm>
      <div className="button-group">
        {options?.map((option, i) => (
          <button
            key={`toggle-${i}`}
            className={option.value === selected ? "active" : null}
            onClick={e => handleClick(e, option.value)}
            // className={`ct-${tag.count}`}
          >
            {option.label}
          </button>
        ))}
      </div>
      {label && <div className="label">{label}</div>}
    </StyledForm>
  )
}
