import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { Section, Content, Row, Col } from "../../styles/StyledElements"

// import { NetworkTagFilterSelect } from "../components/viz/networkTagFilterSelect"
// import { NetworkTagFilterTabs } from "../components/viz/networkTagFilterTabs"
// import { FilteredOutline } from "../components/filteredOutline"

import { FormSelect } from "../formSelect"

import { ForceMapBeeswarm } from "./forceMapBeeswarm"
import { InputToggleSet } from "./inputToggleSet"

const NetworkInterface = styled.div`
  font-family: ${({ theme }) => theme.type.sans};
`

const SectionOutline = styled.div`
  background: #efefef;
  padding-top: 1rem;
  padding-bottom: 2rem;
`

const { slugFormat } = require("../../utilities/slugFormat")

const optionsLayout = [
  { value: "map", label: "Map" },
  { value: "investment", label: "Investment" },
  { value: "employees", label: "Employees" },
]
const optionsS = [
  { value: "none", label: "None" },
  { value: "investment", label: "Investment" },
  { value: "employees", label: "Employees" },
]

export function LayoutForceMap({ dataset }) {
  // console.log("dataset")
  // console.log(dataset)

  const [selectedNodeIds, setSelectedNodeIds] = useState([])
  const [highlighting, setHighlighting] = useState(false)

  const [selectedX, setX] = React.useState("none")
  const [selectedY, setY] = React.useState("none")
  const [selectedS, setS] = React.useState("none")
  const [scaleType, setScaleType] = React.useState("log")
  const [selectedLayout, setLayout] = React.useState("map")

  function updateLayout(value) {
    setLayout(value)

    if (value === "map") {
      setX("none")
    } else {
      setX(value)
    }

    // console.log("UPDATE LAYOUT")
  }

  const filterUpdate = React.useCallback((results, selected) => {
    // console.log("NODES UPDATE")
    // setSelectedNodes(results)
    setSelectedNodeIds(results.map(d => d.id))
    setHighlighting(selected.sectors.length > 0 || selected.tags.length > 0)
  }, [])

  return (
    <>
      <Content>
        <Row>
          <Col>
            <InputToggleSet
              options={optionsS}
              selected={selectedS}
              label={"scale points"}
              updateOption={setS}
            />
          </Col>
        </Row>
        <Row>
          <Col size={3}>
            <ForceMapBeeswarm
              vizId={"forceMap"}
              height="700"
              selectedX={selectedX}
              layout={selectedLayout}
              selectedS={selectedS}
              xScaleType={scaleType}
              nodesData={dataset.set}
              nodesExtent={dataset.geoextent}
            />
          </Col>
          {/* <Col>
            <FormSelect
              id={"bubble"}
              label={"Bubble"}
              value={selectedS}
              onChange={e => setS(e.target.value)}
              options={optionsS}
            />
          </Col> */}
        </Row>
      </Content>
    </>
  )
}
