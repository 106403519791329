import React, { useEffect, useRef, useState } from "react"
import * as d3 from "d3"
//import tooltip } from "./tooltip.module.css"
import styled from "styled-components"
// import D3Component from "./D3Component"

// import datacsv from "./data.csv"

import boroBoundaries from "../../content/data/nyc-borough-boundaries-s001.json"
import boroBounds from "../../content/data/nyc-borough-bounds-crop.json"

const vizTitle = `Enterprises founded or dissolved by year`
const vizDesc = `Chart and map showing enterprises founded or dissolved by year` // Accessibility
const vizSource = `Source: Urban Tech Hub Analysis`

const xLabel = "Year"
const yLabel = "weight (Teratonnes)"

const ChartWrapper = styled.div`
  /* margin: 0 auto; */
  position: relative;

  /* svg {
    width: 100%; // Was breaking IE display
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: auto;
    }
  } */

  .axis {
    font-size: 0.7rem;
    font-family: "DIN-2014", sans-serif;
    font-weight: 400;
  }

  .axis-title {
    font-size: 0.9rem;
    font-family: "DIN-2014", sans-serif;
    font-weight: 700;
    fill: rgba(0, 0, 0, 0.6);
  }

  .background {
    fill: rgba(0, 0, 0, 0.05);
  }

  .tick line {
    stroke: #fff;
    stroke-width: 2px;
  }

  h4 {
    margin-top: 1rem;
  }
`
const ChartInfo = styled.div`
  position: absolute;
  top: 10px;
  left: 18px;
  font-family: ${props => props.theme.type.sans};

  .year {
    margin-top: 1rem;
    font-size: 2rem;
    line-height: 1;
    font-weight: 700;
  }

  .legend {
    font-size: 0.8rem;
    display: flex;
    flex-basis: 0;
  }

  .item {
    display: flex;
    align-items: center;
    margin-right: 1.25rem;
  }

  .icon {
    height: 0.8rem;
    width: 0.8rem;
    background-color: #333;
    border-radius: 50%;
    margin-right: 0.25rem;
  }

  .existing {
    .icon {
      background-color: #777777;
    }
  }

  .new {
    .icon {
      background-color: #0273d3;
    }
    .value {
      font-weight: 700;
      color: #0273d3;
    }
  }

  .dissolved {
    .icon {
      background-color: #eda229;
    }
    .value {
      font-weight: 700;
      color: #eda229;
    }
  }
`

export function FoundedMapChart({
  dataset,
  scrubberYear = 2020,
  chartStartYear = 1995,
  vizId = "founded-map",
  height = 600,
}) {
  let width = 700
  const selectVal = "founded"

  const [dataPointsTotal, setDataPointsTotal] = useState(null)
  const [dataPointsNew, setDataPointsNew] = useState(null)
  const [dataPointsDissolved, setDataPointsDissolved] = useState(null)

  let cPoint = {
    //'legacy': '#FFA874',
    active: "#777777", //'#003B64'
    new: "#0273d3",
    dissolved: "#eda229",
  }

  const divRef = useRef(null)
  // const selectionRef = useRef(null)

  function drawChart() {
    //console.log("DRAW FOUNDED CHART")
    // console.log(dataset)

    // let selectionSVG = d3.select(divRef.current).select("svg") // Unused

    width = divRef.current.getBoundingClientRect().width
    let margin = { top: 0, right: 0, bottom: 0, left: 0 }
    let innerHeight = height - margin.top - margin.bottom
    let innerWidth = width - margin.left - margin.right

    // filtering previous teams that havent scored more than 2 points in a season.
    // let selectSeries = standings.series.filter(d => d.maxpoints > 2)

    // Define SVG
    const svg = d3
      .create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("id", vizId)
      .attr("aria-label", vizDesc)

    // Main chart group/area inset by top/left margin
    const group = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    function isNum(n) {
      return !isNaN(parseFloat(n)) && isFinite(n) && n !== ""
    }

    let nodes = dataset.set.map(d => Object.assign({}, d))

    let dataFiltered = nodes
      .filter(d => d[selectVal] <= scrubberYear)
      .filter(d => !isNum(d["dissolved"]) || d["dissolved"] >= scrubberYear)

    setDataPointsTotal(dataFiltered.length)
    setDataPointsNew(nodes.filter(d => d[selectVal] === scrubberYear).length)
    setDataPointsDissolved(
      nodes.filter(d => d["dissolved"] === scrubberYear).length
    )

    let mapMargin = { top: 0, right: 0, bottom: 0, left: innerWidth * 0.4 }
    let mapInnerHeight = innerHeight - mapMargin.top - mapMargin.bottom
    let mapInnerWidth = innerWidth - mapMargin.left - mapMargin.right

    const projection = d3.geoMercator().fitExtent(
      [
        [0, 0],
        [mapInnerWidth, mapInnerHeight],
      ],
      boroBounds
    ) // tsRoutes

    const path = d3.geoPath().projection(projection)

    let g = svg.append("g")

    let mapGroup = svg
      .append("g")
      .attr("class", "map-group")
      .attr("transform", `translate(${mapMargin.left},${mapMargin.top})`)

    let bounds = mapGroup
      .append("g")
      .attr("class", "hydro")
      .selectAll("path")
      .data(boroBoundaries.features)
      .enter()
      .append("path")
      .attr("d", path)
      .style("fill", "none")
      .attr("stroke-width", 0.75)
      .style("stroke", "#CDCDCD")

    // let cdmuni = topojson.feature(tsGeoTopo, tsGeoTopo.objects.nycdmuni).features

    let pointGroup = mapGroup.append("g").attr("class", "points")

    // Update Stations > Data
    let point = pointGroup
      .selectAll("point")
      .data(dataFiltered, d => d["id"])
      .join("g")
      .attr("class", "company")
      .attr("transform", function (d) {
        let point = projection([d.lon, d.lat])
        return `translate(${point[0]},${point[1]})`
      })

    point
      .append("circle")
      .attr("class", "company-point")
      .attr("pointer-events", "none")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", d =>
        d["founded"] === scrubberYear || d["dissolved"] === scrubberYear
          ? 3.5
          : 2.0
      )
      .attr("fill", d => {
        let color = "#333"
        if (d["founded"] === scrubberYear) {
          color = cPoint.new
        }
        if (d["founded"] < scrubberYear) {
          color = cPoint.active
        }
        if (d["dissolved"] === scrubberYear) {
          color = cPoint.dissolved
        }
        return color
      })

    let legendData = [
      //{class: 'legacy', label: 'Dissolved'},
      { class: "active", label: "Existing" },
      { class: "new", label: "New" },
      { class: "dissolved", label: "Dissolved" },
    ]

    // Year Label
    // g.append("text")
    //   .attr("class", function (d) {
    //     return "label"
    //   })
    //   .attr("x", margin.left)
    //   .attr("y", margin.top)
    //   .attr("alignment-baseline", "hanging")
    //   .text(scrubberYear)
    //   .attr("paint-order", "stroke")
    //   .attr("stroke-linejoin", "round")
    //   .attr("stroke", "#FFF")
    //   .attr("stroke-width", "8px")
    //   .attr("fill", function (d) {
    //     return "#AAA"
    //   })
    //   .style("font-size", "36px")

    // g.append("text")
    //   .attr("class", function (d) {
    //     return "label"
    //   })
    //   .attr("x", margin.left + 2)
    //   .attr("y", margin.top + 35)
    //   .attr("alignment-baseline", "hanging")
    //   .text(`Companies: ${dataPointsTotal} (+${dataPointsNew})`)
    //   .attr("paint-order", "stroke")
    //   .attr("stroke-linejoin", "round")
    //   .attr("stroke", "#FFF")
    //   .attr("stroke-width", "8px")
    //   .attr("fill", function (d) {
    //     return "#AAA"
    //   })
    //   .style("font-size", "24px")

    // Source
    // g.append("text")
    //   .attr("class", "chart-source")
    //   .text("Data: Urban Tech Hub Analysis")
    //   .attr("text-anchor", "left")
    //   .attr("x", margin.left)
    //   .attr("y", height - margin.bottom)
    //   .attr("paint-order", "stroke")
    //   .attr("stroke-linejoin", "round")
    //   .attr("stroke", "#FFF")
    //   .attr("stroke-width", "6px")
    //   .attr("fill", function (d) {
    //     return "#999"
    //   })
    //   .style("font-size", "12px")

    // g.append("text")
    //   .attr("class", "chart-note")
    //   .text(`Note: `)
    //   .attr("text-anchor", "left")
    //   .attr("x", margin.left)
    //   .attr("y", height-margin.bottom-16)
    //   .attr('paint-order', 'stroke')
    //   .attr('stroke-linejoin', 'round')
    //   .attr('stroke', '#FFF')
    //   .attr('stroke-width', '6px')
    //   .attr('fill', function(d) {
    //     return '#999';
    //   })
    //   .style('font-size', '12px')

    // Legend
    // let legend = g
    //   .append("g")
    //   .attr("class", "legend")
    //   .attr("transform", `translate(${10},${margin.top + 100})`)

    // var legendElement = legend
    //   .selectAll("engine")
    //   .data(legendData)
    //   .enter()
    //   .append("g")
    //   .attr("class", d => {
    //     return "type-" + d.type
    //   })
    //   .attr("transform", function (d, i) {
    //     return `translate(0,${i * 17})`
    //   })

    // legendElement
    //   .append("circle")
    //   .attr("r", 6)
    //   .attr("fill", d => cPoint[d.class])
    //   .attr("opacity", "1.0")
    //   .attr("paint-order", "stroke")
    //   .attr("stroke", "#FFF")
    //   .attr("stroke-width", "6px")

    // legendElement
    //   .append("text")
    //   .attr("class", function (d) {
    //     return "label"
    //   })
    //   .attr("x", 12)
    //   .attr("dy", ".35em")
    //   .attr("text-anchor", "start")
    //   .text(function (d) {
    //     return d.label
    //   })
    //   .attr("paint-order", "stroke")
    //   .attr("stroke-linejoin", "round")
    //   .attr("stroke", "#FFF")
    //   .attr("stroke-width", "6px")
    //   .attr("fill", function (d) {
    //     return "#777"
    //   })
    //   .style("font-size", "12px")

    // CHART
    const chartHeight = 240
    const chartMargin = { top: 20, right: 20, bottom: 20, left: 40 }
    const chartInnerHeight = chartHeight - chartMargin.top - chartMargin.bottom
    const chartInnerWidth =
      innerWidth * 0.5 - chartMargin.left - chartMargin.right
    const chartid = "chart-lines"

    let selectY = "count"
    let selectX = "year"

    let dataSelect = dataset.founding.filter(d => d[selectX] >= chartStartYear)
    let dataSelectVisible = dataSelect.filter(d => d[selectX] <= scrubberYear)

    let color = {
      positive: "#0273d3",
      negative: "#eda229",
    }

    const chartGroup = group
      .append("g")
      .attr("class", "chart")
      .attr(
        "transform",
        d => `translate(${chartMargin.left},${120 + chartMargin.top})`
      )

    // let y = d3.scaleLinear()
    //   .domain(data.extId)
    //   .range([0, innerHeight,]);

    let y = d3
      .scaleLinear()
      .domain(d3.extent(dataSelect, d => d[selectY]))
      .range([chartInnerHeight, 0])

    let yAxis = d3.axisLeft(y)

    //.tickFormat(dataDict[selectY].format);

    let xExtent = d3.extent(dataSelect, d => d[selectX])

    let xExtentSize = xExtent[1] - xExtent[0]
    let lineWidth = (chartInnerWidth / xExtentSize) * 0.9

    let x = d3
      .scaleLinear()
      .domain(xExtent)
      .range([5, chartInnerWidth - 5])
    let xAxis = d3.axisBottom(x).tickFormat(d3.format("d")).ticks(4)

    chartGroup
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(" + 0 + "," + chartInnerHeight + ")")
      .call(xAxis)
      .call(g => g.selectAll(".domain").remove())

    chartGroup
      .append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .call(g => g.selectAll(".domain").remove())

    // Title
    chartGroup
      .append("text")
      //.text("Year Founded / Dissolved")
      .text("Year")
      .attr("text-anchor", "middle")
      .attr("class", "x-axis-label")
      .attr("y", chartInnerHeight + 36)
      .attr("x", chartInnerWidth / 2)
      .style("fill", "#999")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .style("font-family", "sans-serif")

    chartGroup
      .append("text")
      .text("Enterprises")
      .attr("text-anchor", "middle")
      .attr("class", "y-axis-label")
      .attr(
        "transform",
        "translate(" +
          (0 - chartMargin.left * 0.75) +
          "," +
          chartInnerHeight / 2 +
          ") rotate(-90)"
      )
      .style("fill", "#999")
      .style("font-size", "12px")
      .style("font-weight", "bold")
      .style("font-family", "sans-serif")

    // Lines
    var lines = chartGroup.append("g").attr("class", "project-lines")

    let line = lines
      .selectAll("g")
      .data(dataSelectVisible)
      .join("g")
      .attr("class", d => "project-" + d.year)

    line
      .append("line")
      .attr("class", "primary")
      .attr("style", "fill:none !important")
      .attr("stroke-width", lineWidth)
      .attr("stroke", d => {
        return d.type === "founded" ? color.positive : color.negative
      })
      .attr("stroke-opacity", 3)
      .attr("x1", d => x(d[selectX]))
      .attr("x2", d => x(d[selectX]))
      .attr("y1", d => y(0))
      .attr("y2", d => y(d[selectY]))

    chartGroup
      .append("g")
      .attr("class", "baseline")
      .append("line")
      .attr("class", "baseline-line")
      .attr("stroke", d => "#333") // teamColors[d.teamClass]
      .attr("stroke-opacity", 2)
      .attr("x1", d => 0)
      .attr("x2", d => chartInnerWidth)
      .attr("y1", d => y(0))
      .attr("y2", d => y(0))

    return svg
  }

  //ComponentDidMount - Initialize
  useEffect(() => {
    // console.log(`ComponentDidMount`)

    //Append d3 svg to ref div
    var div = d3.select(divRef.current)

    let svg = drawChart()
    if (div.node().firstChild) {
      div.node().removeChild(div.node().firstChild)
    }
    div.node().appendChild(svg.node())
  })

  //Render
  return (
    <ChartWrapper>
      <ChartInfo>
        <div className={"year"}>{scrubberYear}</div>
        <div className={"companies"}>
          Enterprises: <span className={"value"}>{dataPointsTotal}</span>
        </div>
        <div className={"legend"}>
          <div className={"item existing"}>
            <div className={"icon"}></div>Existing
          </div>
          <div className={"item new"}>
            <div className={"icon"}></div>New{" "}
            {dataPointsNew > 0 && (
              <>
                (<span className={"value"}>+{dataPointsNew}</span>)
              </>
            )}
          </div>
          <div className={"item dissolved"}>
            <div className={"icon"}></div>
            Dissolved{" "}
            {dataPointsDissolved > 0 && (
              <>
                (<span className={"value"}>-{dataPointsDissolved}</span>)
              </>
            )}
          </div>
        </div>
      </ChartInfo>
      {/* <h4>{vizTitle}</h4> */}
      <div id={vizId} ref={divRef} />
    </ChartWrapper>
  )
}
