import React, { useEffect, useRef } from "react"
import * as d3 from "d3"
//import tooltip } from "./tooltip.module.css"
import styled from "styled-components"
// import D3Component from "./D3Component"
// import theme from "../../styles/Theme"
import { Link } from "gatsby"

//import boroBoundaries from "../../content/data/nyc-borough-boundaries-10m.json"
import boroBoundaries from "../../content/data/nyc-borough-boundaries-s001.json"
// import boroBounds from "../../content/data/nyc-borough-bounds-crop.json"
import dataDict from "../../content/data/data-dictionary.json"

const { slugFormat } = require("../../utilities/slugFormat")

const NetworkWrapper = styled.div`
  position: relative;

  svg {
    width: 100%; // Was breaking IE display
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: auto;
    }
  }
`

const TooltipBox = styled.div`
  position: absolute;
  text-align: center;
  min-width: 30px;
  border-radius: 4px;
  height: auto;
  background: rgba(250, 250, 250, 0.9);
  border: 1px solid #ddd;
  font-size: 0.8rem;
  padding: 4px 8px;
  text-align: left;
  opacity: 0;
  font-family: ${props => props.theme.type.sans};
  span {
    text-transform: capitalize;
  }
`

const SelectionBox = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: 240px;
  padding: 10px;
  /* background-color: rgba(240, 240, 240, 0.8); */
  box-shadow: rgb(0 0 0 / 10%) 0px 5px 15px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 400;
  border-radius: 6px;
  font-family: ${props => props.theme.type.sans};
  font-size: 0.8rem;

  .type {
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  .name {
    font-family: ${props => props.theme.type.sans};
    font-weight: 700;
    line-height: 1.1;
  }
  .summary {
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }

  .close {
    margin-right: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.coolgrey};
    cursor: pointer;
    background: none;
    transition: all 0.5s ease;
    :hover {
      background: ${({ theme }) => theme.colors.coolgrey};
      color: #fff;
    }
  }

  .node-link {
    text-decoration: none;
    transition: all 0.5s ease;
    :hover {
      text-decoration: underline;
    }
  }
`

export function ForceMapBeeswarm({
  vizId,
  vizDesc = "",
  vizContext,
  scaling = true,
  height,
  selectedLayout,
  linksData,
  nodesData,
  layout = "map",
  selectedX = "none",
  selectedY = "none",
  selectedS = "investment",
  xScaleType = "linear",
  selectedView,
  nodeHighlight,
  colorForecast = false,
  nodeHighlightSet = [],
  nodeHoverTooltip,
  nodeSelection,
  highlighting = false,
  selectedNodeIds = [],
  nodePageLink = false,
  nodeHandleSelection,
  introTransition = false,
  nodesExtent,
}) {
  // const [divWidth, setDivWidth] = React.useState(600)
  // let scaleX = d3.scaleBand().domain(orderTimeframe).range([0, innerWidth])
  // let scaleY = d3.scaleBand().domain(orderCertainty).range([innerHeight, 0])
  // let scaleS = d3.scaleOrdinal().domain(orderImpact).range([8, 12, 16])
  const [selectedNode, setSelectedNode] = React.useState({})
  const [isFirstLoad, setIsFirstLoad] = React.useState(true)
  // const [selectedNodeURL, setSelectedNodeURL] = React.useState()

  let width = 700
  //let height = 700

  const breaks = {
    sm: 500,
    md: 600,
  }

  const rBase = 2

  let nodes = nodesData.map(d => Object.assign({}, d))
  //nodes = nodes.slice(0, 30)

  const divRef = useRef(null)
  // const selectionRef = useRef(null)

  let simulation
  const simulationRef = useRef()

  let bubbleColor = "#F3B632" // Yellow

  function drawGraph() {
    //console.log({ layout })
    let forceMode = layout === "map" ? "map" : "axis"

    let width = divRef.current.getBoundingClientRect().width
    let margin = { top: 20, right: 20, bottom: 160, left: 20 }
    // reduce height for homepage intro at smaller size
    // if (width < breaks.sm) {
    //   height = 600
    // }

    if (forceMode !== "map") {
      height = height * 0.6
    }

    // if (selectedX !== "none" || selectedS !== "none") {
    //   margin.bottom = 120
    // }

    let innerHeight = height - margin.top - margin.bottom
    let innerWidth = width - margin.left - margin.right

    // Initialize Scales

    // Initialize SVG and Element Groups
    const svg = d3
      .create("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("aria-label", vizDesc)

    const mapgroup = svg
      .append("g")
      .attr("class", "map-group")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    const labelgroup = svg
      .append("g")
      .attr("class", "label-group")
      .attr("transform", `translate(${margin.left},${margin.top})`)
    const forcegroup = svg
      .append("g")
      .attr("class", "force-group")
      .attr("transform", `translate(${margin.left},${margin.top})`)
    const axisLabels = labelgroup.append("g").attr("class", "axis-labels")

    ////////////////////////////
    // MAP
    ////////////////////////////

    const projection = d3
      .geoMercator()
      //.fitExtent([[margin.right,margin.top],[innerWidth,innerHeight]],boroughFrame); // tsRoutes
      // .fitExtent(
      //   [
      //     [margin.right, margin.top],
      //     [innerWidth, innerHeight],
      //   ],
      //   boroBounds
      // )
      .fitExtent(
        [
          [0, 0],
          [width, height],
        ],
        nodesExtent
        //boroBounds
      )

    const path = d3.geoPath().projection(projection)

    if (forceMode === "map") {
      //console.log("MAP")
      mapgroup
        .append("g")
        .attr("class", "borough-bounds")
        .selectAll("path")
        .data(boroBoundaries.features)
        .enter()
        .append("path")
        .attr("d", path)
        .style("fill", "none")
        .attr("stroke-width", 0.75)
        .style("stroke", "#CDCDCD")
    }

    // Domain Settings
    // console.log(selectedX)

    let scaleX
    let scaleY
    let scaleS

    //console.log(selectedX)
    if (selectedX !== "none") {
      // scaleX.domain(d3.extent(nodes, d => d[selectedX]))
      // scaleX.range([0, innerWidth])

      if (xScaleType === "linear") {
        let xExtent = d3.extent(nodes, d => d[selectedX])
        scaleX = d3.scaleLinear().domain(xExtent)
      } else if (xScaleType === "log") {
        let xExtent = d3.extent(
          nodes.filter(d => d[selectedX] > 0),
          d => d[selectedX]
        )
        let min = xExtent[0] > 0 ? xExtent[0] : 1
        scaleX = d3.scaleLog().domain([min, xExtent[1]])
      }
      //scaleX.range([0, innerWidth])
      // scaleX.range([innerWidth * 0.2, innerWidth - innerWidth * 0.2]).nice()

      scaleX.range([100, innerWidth - innerWidth * 0.2]).nice()

      //console.log(dataDict[selectedX].format)
      let xFormat = d3.format(dataDict[selectedX].format)

      if (width < breaks.md && selectedX === "investment") {
        //xFormat = d3.format("$.3s")
        xFormat = number => d3.format(".1s")(number).replace("G", "B")
      }

      let xAxis = d3.axisBottom(scaleX).tickFormat(xFormat).ticks(4)

      // if (bSelect.scale === "log") {
      //   xAxis
      // }
      // bSelect

      let xg = labelgroup
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(" + 0 + "," + innerHeight + ")")
        .call(xAxis)
        .call(g => g.selectAll(".domain").remove())

      labelgroup
        .append("text")
        //.text("Label")
        .text(dataDict[selectedX].label)
        .attr("text-anchor", "middle")
        .attr("class", "x-axis-label")
        .attr("y", innerHeight + 36)
        .attr("x", innerWidth / 2)
        .style("fill", "#999")
        .style("font-size", "12px")
        .style("font-weight", "bold")
        .style("font-family", "sans-serif")

      if (selectedX === "investment") {
        labelgroup
          .append("text")
          //.text("Label")
          .text("None")
          .attr("text-anchor", "middle")
          .attr("class", "x-axis-label")
          .attr("y", innerHeight + 16)
          .attr("x", 20)
          .style("fill", "rgba(0,0,0,0.8)")
          .style("font-size", "10px")
          .style("font-family", "sans-serif")
      }
    }

    // Draw Impact Legend if Active
    // Bubble Size
    let rMaxAxis = width < breaks.md ? 12 : 18

    let rMax = forceMode === "axis" ? rMaxAxis : 12
    let rMin = rBase

    //console.log("Range")
    //console.log(d3.extent(nodes, d => d[selectedS]))

    scaleS = d3
      .scaleSqrt()
      .domain([0, d3.extent(nodes, d => d[selectedS])[1]])
      .range([rMin, rMax])

    // Bubble Legend

    if (selectedS !== "none") {
      let rLegend = labelgroup
        .append("g")
        .attr("transform", `translate(${0},${innerHeight + 70})`)

      rLegend
        .append("circle")
        .attr("r", rMin)
        .attr("cx", rMin)
        .attr("fill", "none")
        .attr("stroke", "#CCC")

      rLegend
        .append("circle")
        .attr("r", (rMin + rMax) / 2)
        .attr("cx", (rMin + rMax) / 2)
        .attr("fill", "none")
        .attr("stroke", "#CCC")

      rLegend
        .append("circle")
        .attr("r", rMax)
        .attr("cx", rMax)
        .attr("fill", "none")
        .attr("stroke", "#CCC")

      rLegend
        .append("text")
        .text(dataDict[selectedS].label)
        .attr("x", 6 + rMax * 2)
        .attr("font-size", "10px")
        .attr("font-family", "sans-serif")
        .attr("font-weight", "bold")
        .attr("alignment-baseline", "middle")
        .attr("fill", "#444")
    }
    ////////////////////////////////////////
    // NULL ISLAND for MISSING DATA
    ////////////////////////////////////////
    let nIsleRadius = 40
    //let nIsleCoord = [innerWidth - nIsleRadius, innerHeight + nIsleRadius / 2]
    let nIsleCoord = [innerWidth - nIsleRadius, innerHeight + 90]
    const nIsle = labelgroup
      .append("g")
      .attr("className", "null-island")
      .attr("transform", d => {
        return `translate(${nIsleCoord[0]},${nIsleCoord[1]})`
      })

    function drawNullIsland() {
      nIsle
        .append("circle")
        .attr("r", nIsleRadius)
        .attr("fill", "none")
        .attr("stroke", "#CCC")

      nIsle
        .append("text")
        .text(`${forceMode === "map" ? "Location Missing" : "Data Missing"}`)
        .attr("x", 0)
        .attr("y", -nIsleRadius - 10)
        .attr("text-anchor", "middle")
        .attr("font-size", "10px")
        .attr("font-family", "sans-serif")
        .attr("font-weight", "bold")
        //.attr('alignment-baseline', 'middle')
        .attr("fill", "#444")
    }

    ////////////////////////////////////////
    // SIMULATION
    ////////////////////////////////////////
    simulation = d3
      .forceSimulation(nodes)
      //.force("charge", d3.forceManyBody().strength(-12))
      //.force("center", d3.forceCenter(innerWidth / 2, innerHeight / 2))
      .force("x", d3.forceX())
      .force("y", d3.forceY())
      .on("tick", tick)

    let firstTick = true
    function firstTickAction() {
      //console.log("initialize")
      let inactive = nodes.filter(d => d.active === false).length > 0
      let ungeocoded = nodes.filter(d => d.geocoded === false).length > 0
      if (
        (forceMode === "axis" && inactive) ||
        (forceMode === "map" && ungeocoded)
      ) {
        drawNullIsland()
      }
      firstTick = false
    }

    function tick() {
      if (firstTick) {
        //console.log("firstTick")
        firstTickAction()
      }
      //node.attr("cx", d => d.x).attr("cy", d => d.y)
      node.attr("transform", d => {
        if (d.active) {
          d.x = Math.max(d.radius, Math.min(innerWidth - d.radius, d.x))
          d.y = Math.max(d.radius, Math.min(innerHeight - d.radius, d.y))
        } else {
          d.x = Math.max(d.radius, Math.min(width - d.radius, d.x))
          d.y = Math.max(d.radius, Math.min(height - d.radius, d.y))
        }
        return `translate(${d.x}, ${d.y})`
      })
      // link
      //   .attr("x1", d => d.source.x)
      //   .attr("y1", d => d.source.y)
      //   .attr("x2", d => d.target.x)
      //   .attr("y2", d => d.target.y)
    }

    ////////////////////////////////////////
    // ADD NODES
    ////////////////////////////////////////
    const nodeGroup = forcegroup.append("g").attr("class", "nodes")

    const node = nodeGroup
      .selectAll(".node")
      .data(nodes, d => d.id)
      .join(
        enter =>
          enter
            .append("g")
            .attr("class", "node")
            .attr("transform", d => {
              // console.log(`x: ${d.x} / y: ${d.y}`)
              // d.x = innerWidth / 2
              // d.y = innerHeight / 2
              // return `translate(${innerWidth / 2}, ${innerHeight / 2})`
              // return `translate(${d.x}, ${d.y})`

              if (forceMode === "map") {
                d.xf = d.geocoded
                  ? projection([d.lon, d.lat])[0]
                  : nIsleCoord[0]
                d.yf = d.geocoded
                  ? projection([d.lon, d.lat])[1]
                  : nIsleCoord[1]
              } else {
                //console.log(d[selectedX])
                d.active = isNum(d[selectedX])
                d.xf = d.active ? scaleX(d[selectedX]) : nIsleCoord[0]
                d.yf = d.active ? innerHeight / 2 : nIsleCoord[1]
                if (xScaleType === "log" && d[selectedX] === 0) {
                  d.xf = 20
                }
              }

              d.x = d.xf
              d.y = d.yf
            })
            .call(drag(simulation))
            .append("circle")
            .attr("class", "bubble")
            .attr("opacity", 1)
            // .attr("stroke", "#fff")
            // .attr("stroke-width", 2)
            .attr("r", d => {
              let rVal = d[selectedS]
              d.activeR = isNum(rVal)
              d.radius = d.activeR ? scaleS(rVal) : 2

              if (selectedS !== "none" && d.activeR) {
                d.radius = scaleS(d[selectedS])
              } else {
                d.radius = rBase
              }

              return d.radius
            })
            //.attr("fill", d => colorSet[d.type]),
            .attr("fill", d => "#777")
            .on("mouseover", nodeMouseOver)
            .on("mouseout", nodeMouseOut)
            .on("click", nodeClick),
        // update =>
        //   update
        //     .attr("transform", function (d) {
        //       return `translate(${x(d.date)},${y(d[selectType])})`
        //     })
        //     .attr("opacity", d => (!isNaN(d[selectType]) ? 1 : 0)), // hide if value is NaN
        exit => exit.remove()
      )

    ////////////////////////////////////////
    // NODE SUPPORT FUNCTIONS
    ////////////////////////////////////////
    function drag(simulation) {
      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart()
        d.fx = d.x
        d.fy = d.y
        // event.subject.fx = event.subject.x
        // event.subject.fy = event.subject.y
      }
      function dragged(event, d) {
        d.fx = event.x
        d.fy = event.y
        // event.subject.fx = event.x
        // event.subject.fy = event.y
      }
      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0)
        d.fx = null
        d.fy = null
      }
      return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended)
    }

    const addTooltip = (hoverTooltip, d, x, y) => {
      //div.transition().duration(200).style("opacity", 0.9)
      d3.select(`#${vizId}-tooltip`).style("opacity", 0.9)
      d3.select(`#${vizId}-tooltip`)
        //.html(hoverTooltip(d))
        .html(`<div><strong>${d.company}</strong></div>`)
        .style("pointer-events", "none")
        .style("max-width", "250px")
        .style("z-index", 300)
        .style("left", `${x + 24}px`)
        .style("top", `${y + 24}px`)
    }

    const removeTooltip = () => {
      //div.transition().duration(200).style("opacity", 0)
      d3.select(`#${vizId}-tooltip`).style("opacity", 0)
    }

    function nodeMouseOver(event, d) {
      let fadeOpacity = 0.2
      d3.select(event.target).attr("stroke", "#000")
      addTooltip(nodeHoverTooltip, d, d.x + margin.left, d.y + margin.top)
    }
    function nodeMouseOut(event, d) {
      let isSelected = d3.select(event.target).classed("selected")
      d3.select(event.target).attr("stroke", e => {
        return isSelected ? "red" : "none"
      })
      removeTooltip()
    }

    function nodeClick(event, d) {
      // d3.select(event.target).attr("stroke", "red")
      //d3.select(event.target).classed("selected", true)

      //nodeHandleSelection(d)
      setSelectedNode(d)

      // Highlight Selected Node
      d3.select(".bubble.selected")
        .classed("selected", false)
        .attr("stroke", "none")

      d3.select(event.target).classed("selected", true)
      d3.select(event.target).attr("stroke", "red")

      d3.select(`#${vizId}-nodeSelected`).style("display", "block")

      d3.select(`#${vizId}-nodeSelectedInner`).html(`${tipLayout(d)}`)
    }

    function tipLayout(d) {
      let tipContent = `` // Start content string

      let company = d["company"]
      let cat = d["category"]
      let subcat = d["subcategory"]
      let founded = isNum(d["founded"]) ? `${d["founded"]}` : "N/A"
      // let size = d["size"]
      let location = d["location"]
      // let employees = isNum(d["employees"])
      //   ? d3.format(dataDict["employees"].format)(d["employees"])
      //   : "N/A"
      // let investment = isNum(d["investment"])
      //   ? d3.format(dataDict["investment"].format)(d["investment"])
      //   : "N/A"

      tipContent += `<strong>${company}</strong><br>`
      tipContent += `<div class="details">`
      tipContent += `Category: ${cat}<br>`
      tipContent += `Sub-category: ${subcat}<br>`
      tipContent += `Location: ${location}<br>`
      tipContent += `Founded: ${founded}<br>`
      // tipContent += `Size: ${size}<br>`
      // tipContent += `Employees: ${employees}<br>`
      // tipContent += `Investment: ${investment}`
      tipContent += `</div>`

      return tipContent
    }
    // Update Simulation Forces
    simulation.force(
      "collision",
      d3.forceCollide().radius(function (d) {
        // if (scaling && width < breaks.sm) {
        //   return d.radius + 4
        // } else if (scaling && width < breaks.md) {
        //   return d.radius + 6
        // } else {
        //   return d.radius + 8
        // }
        return d.radius + 1
      })
    )

    //console.log({ forceMode })
    simulation.force("x").x(d => {
      return d.xf
    })

    // .strength(d => {
    //   if (selectedX === "none" && selectedY === "none") {
    //     return 0.05
    //   } else {
    //     if (d.type === "trend") {
    //       return 1.0
    //     } else {
    //       return 0.0
    //     }
    //   }
    // })

    simulation.force("y").y(d => {
      return d.yf
    })

    // .strength(d => {
    //   if (selectedX === "none" && selectedY === "none") {
    //     return 0.05
    //   } else {
    //     if (d.type === "trend") {
    //       return 1.0
    //     } else {
    //       return 0.0
    //     }
    //   }
    // })

    // link simulation to ref so it can be accesses outside of useRef
    simulationRef.current = simulation

    return svg
  }

  // Apply Styles
  function applyStyle(selectionSVG) {
    // Standard Style Update
    selectionSVG
      .selectAll(".bubble")
      .transition()
      .duration(500)
      // .attr("r", d => {
      //   // if (selectedS !== "none" && isNum(d[selectedS])) {
      //   //   d.radius = scaleS(d[selectedS])
      //   // } else {
      //   //   d.radius = rBase
      //   // }
      //   //d.radius = rBase
      //   return d.radius
      // })
      .attr("opacity", 1)
      .attr("fill", d => {
        // console.log({ layout, selectedX, selectedS })
        if (selectedS === "none" || selectedX === "map") {
          //return bubbleColor
          return "#333"
        } else {
          //return "#CCC"
          return d.activeR ? dataDict[selectedS].hex : "#CCC"
        }
      })
  }

  function isNum(n) {
    return !isNaN(parseFloat(n)) && isFinite(n) && n !== ""
  }

  //const simulationRef = React.useRef(simulation)

  //ComponentDidMount - Initialize
  useEffect(() => {
    // console.log(`ComponentDidMount`)
    //Append d3 svg to ref div
    // console.log("nodes")
    // console.log(nodes)
  }, [])

  //ComponentDidUpdate - Run only on dependent variable change
  useEffect(() => {
    // console.log(`ComponentUpdate1`)
  }, [
    nodeHighlight,
    nodeHighlightSet,
    selectedView,
    highlighting,
    colorForecast,
  ])

  useEffect(() => {
    // console.log(`ComponentUpdate2`)
    //simulationRef.current.alpha(1).restart()
    var div = d3.select(divRef.current)

    let svg = drawGraph()
    if (div.node().firstChild) {
      div.node().removeChild(div.node().firstChild)
    }
    div.node().appendChild(svg.node())
  }, [selectedX, selectedY, selectedS])

  // useEffect(() => {
  //   console.log(`ComponentUpdate4`)
  //   console.log(simulationRef)
  //   simulationRef.current.alpha(1).restart()
  // }, [])

  //ComponentDidUpdate - Runs Every Change
  useEffect(() => {
    // console.log(`ComponentUpdate3`)
    var div = d3.select(divRef.current)

    applyStyle(div.select("svg"))
  })
  // console.log(selectedNode.name)
  //Render
  return (
    <NetworkWrapper>
      <SelectionBox id={`${vizId}-nodeSelected`}>
        <div id={`${vizId}-nodeSelectedInner`}>
          {/* <div className="type">{selectedNode.type}</div>
          <div className={"name"}>{selectedNode.company}</div>
          <div className={"employees"}>{selectedNode.employees}</div>
          <div className={"address"}>{selectedNode.address}</div>
          {selectedNode.summary !== null && (
            <div className={"summary"}>{selectedNode.summary}</div>
          )} */}
        </div>
        <button
          className="close"
          onClick={e => {
            // console.log("close SelectionBox")
            d3.select(`#${vizId} .bubble.selected`)
              .classed("selected", false)
              .attr("stroke", "#fff")
              .attr("stroke-width", 0)
            document.getElementById(`${vizId}-nodeSelected`).style.display =
              "none"
          }}
        >
          close
        </button>
        {/* <Link to={`/${selectedNode.type}s/${selectedNode.id}`}>View Page</Link> */}
        {nodePageLink && (
          <Link
            className="node-link"
            to={`/${selectedNode.type}s/${
              selectedNode.name ? slugFormat(selectedNode.name) : ""
            }`}
          >
            View Page
          </Link>
        )}
      </SelectionBox>
      <div id={vizId} ref={divRef} />
      {/* <div>{selectedNode.id}</div> */}
      <TooltipBox id={`${vizId}-tooltip`}></TooltipBox>
    </NetworkWrapper>
  )
}
